import * as React from 'react'
import GlobalData from '../data/global-images'

const LogoFalling = ({ data }) => {
    const images = GlobalData();
    return (
        <section className='homepage-logo'>
            <img className='logo-image float' src={images.global.fallingLogo.url} height={images.global.fallingLogo.height} width={images.global.fallingLogo.width} alt={images.global.fallingLogo.alt} loading='lazy'/>
            <span className='cloud-background cloud-background-foreground'>
                <img className='cloud-falling-animate-fast' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
                <img className='cloud-falling-animate-fast' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
                <img className='cloud-falling-animate-fast' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
            </span>
            <span className='cloud-background cloud-background-background'>
                <img className='cloud-falling-animate' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
                <img className='cloud-falling-animate' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
                <img className='cloud-falling-animate' src={images.global.verticalCloud.url} alt={images.global.verticalCloud.alt} loading='lazy' width={images.global.verticalCloud.width} height={images.global.verticalCloud.height} />
            </span>
        </section>
    )
}

export default LogoFalling