import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";

import PageBuilder from "../components/pagebuilder";

import { useI18next } from "gatsby-plugin-react-i18next";
import LogoFalling from "../sections-static/logo-falling";
import FeaturesCarousel from "../page-modules/features-carousel";

const FeaturePage = ({ data }) => {
  const { language } = useI18next();
  return (
    <Layout>
      {data.page.nodes.map((page) => (
        <main key={page.id} className="page-builder">
          <HelmetDatoCms seo={page.seoMetaTags} />
          <PageBuilder lang={language} data={page.builder} />
          <FeaturesCarousel
            data={data.otherFeatures}
            title={page.otherFeaturesTitle}
            lang={language}
          />
          <LogoFalling />
        </main>
      ))}
    </Layout>
  );
};
export default FeaturePage;

export const query = graphql`
  query FeaturePageQuery($language: String!, $id: String!) {
    page: allDatoCmsFeaturePage(
      filter: { originalId: { eq: $id }, locale: { eq: $language } }
    ) {
      nodes {
        originalId
        id
        title
        slug
        otherFeaturesTitle
        builder {
          ... on DatoCmsImageContent {
            ...ImageContent
          }
          ... on DatoCmsColumnedList {
            ...ColumnedList
          }
          ... on DatoCmsCarousel {
            ...Carousel
          }
          ... on DatoCmsFeatureCarousel {
            ...Features
          }
          ... on DatoCmsNicheSelector {
            ...Niches
          }
          ... on DatoCmsCustomerTestimonial {
            ...Testimonials
          }
          ... on DatoCmsValuesList {
            ...Values
          }
          ... on DatoCmsCallToActionBox {
            ...CTABox
          }
          ... on DatoCmsImageVideo {
            ...ImageVideo
          }
        }
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
    otherFeatures: allDatoCmsFeaturePage(
      filter: { originalId: { ne: $id }, locale: { eq: $language } }
    ) {
      nodes {
        originalId
        id
        title
        slug
        cta
        description
        illustration {
          url(imgixParams: { auto: "enhance", q: 100, w: "960" })
          alt
        }
      }
    }
  }
`;
